import { Button, Stack, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import route from '../Routes';
import { capFirstLetter, formatDate, formatDateTime, formatDateWithJs, getTruckName, makeTxtOverFLow, } from '../utils/format';
import StyledTableContainer from './StyledTableContainer';
import { useTranslation } from "react-i18next";
import { Close, Verified, Warning } from '@mui/icons-material';
import { useAuthContext } from '../context/AuthContext';
import { customAlert, notify } from './notify';
import { putTrkVfyStatus } from '../services/trkOp-service';


function SinglePenTrkTable({ trkRows, vfyStatusType, refetch, height }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setAsyncLoading } = useAuthContext();


    const columns = vfyStatusType == 'pending' ? [
        {
            id: 'name',
            label: t("bo.tpReqTable.trkOp"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'regNo',
            label: t("bo.trkInfoPg.trkNum"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'truckTypes',
            label: t("bo.tpReqTable.trk"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'fcExp',
            label: t("bo.tpReqTable.fcExpDate"),
            align: 'left',
            minWidth: 30
        },
        {
            id: 'insExp',
            label: t("bo.tpReqTable.insExpDate"),
            align: 'left',
            minWidth: 30
        },
        {
            id: 'c_at',
            label: t("bo.tpReqTable.postedOn"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'pbtn',
            label: '',
            align: 'left',
            minWidth: 150
        },
    ] : [
        {
            id: 'name',
            label: t("bo.tpReqTable.trkOp"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'regNo',
            label: t("bo.trkInfoPg.trkNum"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'truckTypes',
            label: t("bo.tpReqTable.trk"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'fcExp',
            label: t("bo.tpReqTable.fcExpDate"),
            align: 'left',
            minWidth: 30
        },
        {
            id: 'insExp',
            label: t("bo.tpReqTable.insExpDate"),
            align: 'left',
            minWidth: 30
        },
        {
            id: 'c_at',
            label: t("bo.tpReqTable.postedOn"),
            align: 'left',
            minWidth: 50
        },
        {
            id: 'rbtn',
            label: '',
            align: 'left',
            minWidth: 150
        },
    ];
    console.log(columns)




    async function handleNameClick(reqRow) {
        navigate(route.boTrkOpInfo + reqRow.truckopId);
    }

    async function handleRegNoClick(reqRow) {
        navigate(route.boTrkInfo + reqRow.id);
    }

    async function updateVfyStatusClick(reqRow, status) {
        setAsyncLoading(true);
        try {
            await putTrkVfyStatus({ trkId: reqRow.id, vfyStatus: status })
            refetch();
        } catch (err) {
            console.log(err);
            if (err === 409)
                notify("error", "Truck Already Registred");
            else
                customAlert(err)
        }
        setAsyncLoading(false)
    }

    return (
        <StyledTableContainer height={height ?? "83%"}>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                            key={column.id}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(trkRows || []).map((reqRow) => {
                    return (
                        <TableRow hover tabIndex={-1} key={reqRow.truckOpReqId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            {columns.map((column) => {
                                const value = reqRow[column.id];
                                return (
                                    (column.id === 'name') ?
                                        <TableCell key={column.id} align={column.align}>
                                            <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { handleNameClick(reqRow); }}>
                                                {capFirstLetter(reqRow.trkOpFName) + " " + capFirstLetter(reqRow.trkOpLName)}&nbsp; &nbsp; &nbsp;({reqRow.trkOpContact})
                                            </Button>
                                        </TableCell> : (column.id === 'truckTypes') ?
                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                {getTruckName(JSON.parse(reqRow?.type || "{}"))}
                                            </TableCell> : (column.id === 'regNo') ?
                                                <TableCell key={column.id} align={column.align} >
                                                    <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { handleRegNoClick(reqRow); }}>
                                                        {reqRow.regNo}
                                                    </Button>
                                                </TableCell> : (column.id === 'c_at') ?
                                                    <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                        {formatDateTime(reqRow.c_at)}
                                                    </TableCell> :
                                                    (column.id === 'pbtn') ?
                                                        <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                                <Button variant="contained" color="success" size="small" startIcon={<Verified />} onClick={() => updateVfyStatusClick(reqRow, 'verified')}>
                                                                    Verify
                                                                </Button>
                                                                <Button variant="outlined" color="error" size="small" startIcon={<Close />} onClick={() => updateVfyStatusClick(reqRow, 'rejected')}>
                                                                    Reject
                                                                </Button>

                                                            </Stack>
                                                        </TableCell> : (column.id === 'rbtn') ?
                                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                                    <Button variant="contained" color="success" size="small" startIcon={<Verified />} onClick={() => updateVfyStatusClick(reqRow, 'verified')}>
                                                                        Verify
                                                                    </Button>
                                                                    <Button variant="outlined" color="warning" size="small" startIcon={<Warning />} onClick={() => updateVfyStatusClick(reqRow, 'pending')}>
                                                                        Pending
                                                                    </Button>

                                                                </Stack>
                                                            </TableCell> : (column.id === 'fcExp') ?
                                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                    {formatDateWithJs(reqRow?.fc_expDate)}
                                                                </TableCell> : (column.id === 'insExp') ?
                                                                    <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                                        {formatDateWithJs(reqRow?.ins_expDate)}
                                                                    </TableCell> :
                                                                    <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }} >
                                                                        {column.format && typeof value === 'number' ? column.format(value) : makeTxtOverFLow(value, 30)}
                                                                    </TableCell>);
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>

        </StyledTableContainer>
    )
}

export default SinglePenTrkTable